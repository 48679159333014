import dayjs from "dayjs";

import useAsyncFetch from "./useAsyncFetch";

const useRegisterContent = ({ immediate }: { immediate?: boolean } = {}) => {
	const route = useRoute();
	const cookie = useCookie<string | null>("registerContentID", {
		path: "/",
		expires: dayjs().add(1, "year").toDate()
	});
	const id = (route.query.BNR as string) || cookie.value;

	if (!id) {
		return {
			data: ref(null)
		};
	}

	cookie.value = id;

	const { data } = useAsyncFetch({
		path: "/rest/registration-content/{id}",
		fetchOptions: { path: { id } },
		method: "get",
		options: {
			immediate,
			cached: true,
			transform: (res) => ({ ...res.data })
		}
	});

	return {
		data
	};
};

export default useRegisterContent;
